import { EMPTY_TOKEN, ClientError, INVALID_TOKEN } from '@bridge/core/util/error'
import { type UserDetail } from 'app/common/constants/user-type'
import serverAccess from 'app/utils/axios'
import { LocalStorage } from 'app/utils/storage'
import type { UserType } from './CommonRepository'

let repository: {
	getUserInfo: () => Promise<UserDetail>
	updateUserInfo: (newUser: Partial<UserType>) => Promise<void>
	userQuit: (quitReason: string) => Promise<void>
	updateLastLoginDate: () => Promise<void>
}

function userRepository () {
	if (repository) return repository

	repository = {
		getUserInfo: async () => {
			const { data } = await serverAccess.get('/ug/consulting/user')
			return data
		},
		updateUserInfo: async (newUser) => {
			await serverAccess.patch('/ug/consulting/user', {
				...newUser,
			})
		},
		userQuit: async (quitReason) => {
			await serverAccess.post('/ug/consulting/user-quit', {
				quitReason,
			})
		},
		updateLastLoginDate: async () => {
			await serverAccess.patch(
				'/ug/consulting/users/last-login-date',
			)
		},
	}
	return repository
}

export default userRepository()

export const userFetcher = async () => {
	const idToken = LocalStorage.getIdToken()

	if (!idToken) {
		throw new ClientError({
			message: '로그인이 되어있지 않습니다.',
			code: EMPTY_TOKEN,
		})
	}

	const { data } = await serverAccess.get<UserDetail>('/ug/consulting/user')

	if (!data) {
		throw new ClientError({
			message: '존재하지 않는 유저입니다.',
			code: INVALID_TOKEN,
		})
	}

	return data
}

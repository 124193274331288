import { EMPTY_TOKEN, INVALID_TOKEN, isClientError } from '@bridge/core/util/error'
import { userFetcher } from 'app/api/UserRepository'
import { API_USER } from 'app/common/constants/swr-key'
import useSWR from 'swr'

// { revalidate: false } 일 경우 유저 정보 갱신 안함.
// 외부에 인해 변하는 유저의 실시간 데이터가 필요할 때 { revalidate: true }
// ex) user.consultant, user.emailAuth, ... 등등
export const useSuspenseUser = ({ revalidate = false } = {}) => {
	const { data: user, mutate } = useSWR(
		API_USER,
		userFetcher,
		{
			suspense: true,
			...(!revalidate && {
				revalidateIfStale: false,
				revalidateOnFocus: false,
				revalidateOnReconnect: false,
			}),
		},
	)

	return {
		mutate,
		user,
	}
}

export const useUser = ({ revalidate = false } = {}) => {
	const { data: user, error, mutate, isLoading, isValidating } = useSWR(
		API_USER,
		userFetcher,
		{
			suspense: false,
			...(!revalidate && {
				revalidateIfStale: false,
				revalidateOnFocus: false,
				revalidateOnReconnect: false,
			}),
		},
	)

	// 토큰이 없거나 토큰으로 조회한 유저 정보가 없을 때
	const isLoggedOut: boolean = isClientError(error) && (
		error.code === EMPTY_TOKEN ||
		error.code === INVALID_TOKEN
	)

	const isLoggedIn = !error && !!user

	return {
		error,
		mutate,
		user,
		isLoading,

		isLoggedOut,
		isLoggedIn,
		isValidating,
	}
}
